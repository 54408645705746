<template>
  <div class="signed">
    <div class="top">
      <img :src="siteBg" class="sitebg-img">
      <div class="top-site">
        <div class="top-wrap">
          <div class="flex">
            <div class="station_name">{{siteInfo.stationName}} <span v-if="stationAlias">别称：{{stationAlias}}</span></div>
            <div style="padding: 0.21rem 0 0 0.1rem" @click="editDialogShow = true"><van-icon name="edit" color="#3F7C53" size="0.4rem"/></div>
          </div>
          <div class="station_dept">所属部门：<span>{{siteInfo.deptName}}</span></div>
        <!--  <div class="station_dept">合作业务：<span>{{siteInfo.coopBiz}}</span></div>-->
          <div class="station_dept">推荐来源：<span>{{siteInfo.recommendSource}}</span></div>
          <div class="station_dept">结佣方式：<span>{{siteInfo.incomeMethod}}</span></div>
            <div class="station_dept" v-if="siteInfo.stationTagDesc!=''">站点类型：<span>{{siteInfo.stationTagDesc}}</span></div>
            <div class="station_dept" v-if="siteInfo.coopBizCode == 'AGRI_FINANCE'">合作银行：<span>{{siteInfo.cooperationBank}}</span></div>
          <img :src="signedImg" class="signed-img">
          <div class="seeBtn"  @click="siteInfoClick">查看站点</div>
         <div class="seeBtn1"   @click="siteFileClick">站点档案</div>
          <div class="seeBtn2"   @click="labelManagement">标签管理</div>
       </div>
       <div class="bank-wrap">
         <div class="flex">
           <div class="left">编号：{{siteInfo.areaNo}}</div>
           <div class="right">管理责任人：{{siteInfo.marketerName}}</div>
         </div>
         <div class="flex">
           <div class="left">推荐码：{{siteInfo.stationCode}}</div>
           <div class="right">站长：{{siteInfo.masterName}}({{siteInfo.age}}岁,{{siteInfo.gender}})</div>
         </div>
       </div>
     </div>
   </div>

   <div class="img-wrap">
     <img v-if="siteInfo.masterImg" :src="siteInfo.masterImg" class="site-img" @click="imgShowClick(siteInfo.masterImg)">
     <img v-if="siteInfo.storefrontSignboard" :src="siteInfo.storefrontSignboard" class="site-img" @click="imgShowClick(siteInfo.storefrontSignboard)">
   </div>

    <div class="milestone">
     <div class="com-name">站点发展里程碑</div>
     <swiper :options="swiperOption">
       <swiper-slide v-for="(item,index) in milestoneList" :key="index">
         <div class="swip-item">
           <div class="time-item">{{item.time}}</div>
           <div class="icon-item" :class="{ 'icon-unfinished' : !item.finished }"></div>
           <img :src="item.finished ? item.img_finished : item.img_unfinished" class="img-icon">
         </div>
       </swiper-slide>
     </swiper>
   </div>

   <div v-if="siteInfo.stationStatusName == 'SIGNED'&&siteInfo.coopBizCode == 'AGRI_FINANCE'" class="deatils">
     <div class="line-chart">
       <div class="flex chart-title">
         <div>时点余额趋势</div>
         <div @click="seeDetail">查看详情</div>
       </div>

      <img :src="timing" class="timing-img">
      <img :src="cusNum" class="timing-img" style="margin-left:1.6rem;width:1.26rem">
     <lineChart :lineList="lineData"></lineChart>
     </div>
     <div class="update-time">更新时间：{{updateTime}}</div>
     <!-- <div class="bold deatils_title">业绩统计</div>
     <performanceInformationCell :stationNo="this.$route.query.stationNo" class="deatils_cell"></performanceInformationCell> -->
    </div>
      <!--
          <van-overlay :show="imgshow" @click="imgshow = false" style="display:flex; align-items:center">
            <img :src="filePath" style="width:100%"/>
          </van-overlay>
      -->
          <van-overlay :show="editDialogShow" class="editDialog flex">
            <div class="edit-content">
              <div class="edit-title">自定义站点别称</div>
              <van-field v-model="stationAlias" label="站点别称" placeholder="请输入自定义站点名称" input-align="right" maxlength="5"/>
              <div class="box-button flex">
                <van-button type="default" @click="editDialogShow = false">取消</van-button>
                <van-button type="default" @click="editConfirm">确认</van-button>
              </div>
            </div>
          </van-overlay>


        </div>
      </template>

      <script>
      import 'swiper/swiper-bundle.css'
      import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
      import { Icon, Button, Field, Toast, Checkbox, Tab, Tabs } from 'vant'
      import { getSiteByStationNo, getStationPerPeriodPerformanceList, getStationMilestone, updateStationAlias } from '@/utils/api'
      import common from '@/utils/common'
      import performanceInformationCell from "../StationAgent/stationAgentsubviews/performanceInformationCell";
      import lineChart from '../PS/lineChart'
      import {uwStatisticAction, appAction} from "@/utils/util";
      import { FINISH_STATION_CHANGE } from '@/utils/constantNum'
      export default {
        components:{
          lineChart,
          [Icon.name]: Icon,
          [Button.name]: Button,
          [Field.name]: Field,
          [Toast.name]: Toast,
          [Checkbox.name]: Checkbox,
          [Tab.name]: Tab,
          [Tabs.name]: Tabs,
          performanceInformationCell,
          Swiper,
          SwiperSlide
        },
        directives: {
          swiper: directive
        },
        data() {
          return {
            siteBg:require('@/assets/img/site/siteBg.png'),
            signedImg: require('@/assets/img/site/signed.png'),
            logoImg: require('../../assets/img/logo_f.png'),
            checked: false,
            stepArr: [],
            stepDesc: '',
            signed: false,
            siteInfo: {},
            redirectToSiteInfoPage: false,
            stationNo:'',
            uniqueNo:'',
            imgshow: false,
            filePath: '',
            lineData: [],
            timing: require('@/assets/img/ps/timing.png'),
            updateTime: '',
            cusNum: require('@/assets/img/ps/cusNum.png'),
            swiperOption: {
              slidesPerView: 'auto',
              // spaceBetween: 10,
            },
            milestoneList:[
              {img_finished: require('@/assets/img/milestone/a_finished.png'), img_unfinished: require('@/assets/img/milestone/a_unfinished.png'), time: '2021.02.10', finished: false, name: 'CREATED'},
              {img_finished: require('@/assets/img/milestone/b_finished.png'), img_unfinished: require('@/assets/img/milestone/b_unfinished.png'), time: '2021.02.10', finished: true, name:'JOINED'},
              {img_finished: require('@/assets/img/milestone/c_finished.png'), img_unfinished: require('@/assets/img/milestone/c_unfinished.png'), time: '2021.02.10', finished: false, name:'SIGNED'},
              {img_finished: require('@/assets/img/milestone/d_finished.png'), img_unfinished: require('@/assets/img/milestone/d_unfinished.png'), time: '2021.02.10', finished: true, name:'DEVICE_COMPLETED'},
              {img_finished: require('@/assets/img/milestone/e_finished.png'), img_unfinished: require('@/assets/img/milestone/e_unfinished.png'), time: '2021.02.10', finished: true, name:'DECORATE_COMPLETED'},
              {img_finished: require('@/assets/img/milestone/f_finished.png'), img_unfinished: require('@/assets/img/milestone/f_unfinished.png'), time: '2021.02.10', finished: true, name:'OPEN_CEREMONY'},
              {img_finished: require('@/assets/img/milestone/g_finished.png'), img_unfinished: require('@/assets/img/milestone/g_unfinished.png'), time: '2021.02.10', finished: true, name:'STANDARDIZTION'},
              {img_finished: require('@/assets/img/milestone/h_finished.png'), img_unfinished: require('@/assets/img/milestone/h_unfinished.png'), time: '2021.02.10', finished: true, name:'DEPOSIT_COMPLETED'},
              {img_finished: require('@/assets/img/milestone/i_finished.png'), img_unfinished: require('@/assets/img/milestone/i_unfinished.png'), time: '2021.02.10', finished: true, name:'TWENTY_COMPLETED'},
              {img_finished: require('@/assets/img/milestone/j_finished.png'), img_unfinished: require('@/assets/img/milestone/j_unfinished.png'), time: '2021.02.10', finished: true, name:'HUNDRED_COMPLETED'},
            ],
            editDialogShow: false,
            stationAlias: '',
          }
        },
        mounted(){
          this.getsiteInfo()
          this.togetStationMilestone()
          uwStatisticAction('/waitingSign','站点详情站点状态')
        },
        methods:{
          editConfirm() {
            if(!this.stationAlias){
              this.$toast('请输入自定义站点名称')
              return
            }
            updateStationAlias({
              stationNo: this.$route.query.stationNo,
              stationAlias: this.stationAlias
            }).then(res=>{
               if (res.code == 200) {
                if (res.data.businessCode && res.data.businessCode === 200) {
                  this.editDialogShow = false
                }
               }
            })
          },
          togetStationMilestone() {
            getStationMilestone({
              stationNo: this.$route.query.stationNo
            }).then(res=>{
               if (res.code == 200) {
                if (res.data.businessCode && res.data.businessCode === 200) {
                  // this.milestoneList = res.data.data
                  let dataArr = res.data.data
                  for(let obj of dataArr){
                    for(let [n,m] of this.milestoneList.entries()){
                      if(obj.msKey == m.name){
                        m.finished = obj.complete
                        m.time = obj.completeDate ? obj.completeDate : ' '
                      }
                    }
                  }
                }
              }
            })
          },
          siteInfoClick() {
            this.$router.push({name:'siteInfo', query: {stationNo: this.$route.query.stationNo}})
          },
            haowuInfoClick(){
                this.$router.push({name:'haowuInfo', query: {stationNo: this.$route.query.stationNo}})
            },
            siteFileClick(){
                this.$router.push({name:'siteFileInfo', query: {stationNo: this.$route.query.stationNo}})
            },
            labelManagement(){
                this.$router.push({name:'labelManagement', query: {stationNo: this.$route.query.stationNo}})
            },
          seeDetail() {
            this.$router.push({ name: 'performanceDetail', query: {stationNo :this.$route.query.stationNo}})
          },
          togetStationPerPeriodPerformanceList () {
            getStationPerPeriodPerformanceList({
              stationNo: this.$route.query.stationNo,
            }).then(res => {
              if (res.code == 200) {
                if (res.data.businessCode && res.data.businessCode === 200) {
                  this.lineData = res.data.data
                  this.updateTime = res.data.updateTime
                }
              }
            })
          },
          imgShowClick(url){
            this.imgshow = true
            this.filePath = url
          },
          getsiteInfo() {
            getSiteByStationNo({
              stationNo: this.$route.query.stationNo
            }).then(res=>{
              if(res.code ==200){
                if(res.data && res.data.businessCode ==200){
                  this.siteInfo = res.data.data

                  var baseUrl = process.env.NODE_ENV != 'production' ? 'http://124.70.167.116:20081' + '/file/' : window.location.origin + '/file/'
                  var tokenUrl = '?Authorization=' + common.getItem('wtToken')

                  if(this.siteInfo.masterImg) this.siteInfo.masterImg = baseUrl + this.siteInfo.masterImg + tokenUrl
                  if(this.siteInfo.storefrontSignboard) this.siteInfo.storefrontSignboard = baseUrl + this.siteInfo.storefrontSignboard + tokenUrl

                  let userrole
                  if(common.getItem('jumpFrom') != undefined){
                    userrole = this.$route.query.userRole
                  }else{
                    userrole = common.getItem('userRole')
                  }
                  this.redirectToSiteInfoPage =  userrole == 4 ? false : true
                  this.stationAlias = res.data.data.stationAlias
                  // 调用报表接口
                  if(this.siteInfo.stationStatusName == 'SIGNED'){
                    this.$nextTick(()=>{
                      this.togetStationPerPeriodPerformanceList()
                    })
                  }

                }
              }
            })
          },
          modifyInfoClick() {
            let p = {
              stationNo:this.siteInfo.stationNo
            }
            appAction(FINISH_STATION_CHANGE, p)
            this.$router.push({name: 'newsiteList', query:{stationNo: this.siteInfo.stationNo}})
          }
        }
      }
      </script>

      <style lang="less" scoped>
      .signed{
        background: #F7F8F9;
        .van-overlay{
          z-index: 11;
        }
        height: 100%;
        font-size: 0.3rem;
        box-sizing: border-box;
        .flex{
          display: flex;
        }
        .img-wrap{
          padding: 0.24rem 0.32rem;
        }
        .editDialog{
          align-items:center;
          .edit-content{
            width: 6.5rem;
            height: 3.14rem;
            background: #fff;
            margin: 0 0.5rem;
            position: relative;
            border-radius: 0.16rem;
            .edit-title{
              text-align: center;
              color:#333;
              font-size: 0.36rem;
              margin-top: 0.4rem;
              margin-bottom: 0.25rem;

            }
          }
        }
        .box-button{
          position: absolute;
          bottom: 0;
          left: 0;
          justify-content: center;
          .van-button{
            width: 3.25rem;
            height: 1rem;
            line-height: 1rem;
            font-size: 0.36rem;
            &:nth-last-child(1){
              margin-right: 0;
              color: #3F7C53;
            }
          }
        }
        .top{
          position: relative;
          color: #fff;
          background: #F7F8F9;
          .sitebg-img{
            width: 100%;
          }
          .siteImg-img{
            width: 100%;
          }
          .signed-img{
            position: absolute;
            width: 1.44rem;
            right:0;
            top:0;
          }
          .seeBtn{
            position: absolute;
            color: #3F7C53;
            background: rgba(63, 124, 83, 0.1);
            right: 0.4rem;
            top: 1rem;
            width:1.5rem;
            height: 0.4rem;
            line-height: 0.5rem;
            border-radius: 0.25rem;
            font-size: 0.24rem;
            text-align: center;
          }
          .seeBtn1{
            position: absolute;
            color: #3F7C53;
            background: rgba(63, 124, 83, 0.1);
            right: 0.4rem;
            top: 1.6rem;
            width:1.5rem;
            height: 0.4rem;
            line-height: 0.5rem;
            border-radius: 0.25rem;
            font-size: 0.24rem;
            text-align: center;
          }
            .seeBtn2{
                position: absolute;
                color: #3F7C53;
                background: rgba(63, 124, 83, 0.1);
                right: 0.4rem;
                top: 2.2rem;
                width:1.5rem;
                height: 0.4rem;
                line-height: 0.5rem;
                border-radius: 0.25rem;
                font-size: 0.24rem;
                text-align: center;
            }
          .top-site{
            margin: -1rem auto;
            z-index:10;
            position: relative;
            background: #fff;
            margin: -2rem 0.3rem 0;
            box-shadow: 0px 18px 26px 0px rgba(0,0,0,0.05);
            border-radius: 16px;
            .top-wrap{
              width: 100%;
              padding: 0.4rem;
              .site-no{
                color: #999999;
                font-size: 0.26rem;
              }
              .station_name{
                color: #333;
                font-size: 0.4rem;
                margin-top: 0.1rem;
                font-weight: 400;
                span{
                  color: #3F7C53;
                  font-size: 0.24rem;
                }
              }
              .station_dept{
                color: #9B9B9B;
                font-size: 0.26rem;
                margin-top: 0.05rem;
              }
            }
            .content-wrap{
              justify-content: space-between;
            }
          }
          .bank-wrap{
            width: 100%;
            background:#F6F9F7;
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
            padding: 0.24rem 0.4rem;
            .left, .right{
              flex: 1;
              color: #4A4A4A;
              font-size: 0.24rem;
            }
          }
        }
        .milestone{
          margin: 0.1rem 0rem 0.1rem 0rem;
          .swiper-slide {
            width: calc(~"25%"); //每一块宽度
            margin-right: 5px;
            &:nth-last-child(1){
              margin-right: 0.3rem;
              .icon-item{
                &:after{
                  width: 0.8rem !important;
                  height: 0.02rem;
                  right: -0.8rem !important;
                  top: 0.1rem;
                  background: #ECECEC;
                  position: absolute;
                  content: '';
                }
              }
            }
          }
          .com-name{
            color: #141A22;
            font-size: 0.36rem;
            margin-left: 0.3rem;
          }
          .img-icon{
            width: 1.6rem;
            height: 1.53rem;
            box-shadow: 0px 18px 26px 0px rgba(0,0,0,0.05);
          }
          .swip-item{
            padding: 0.2rem 0 0.8rem 0.3rem;
            .time-item{
              color: #4A4A4A;
              font-size: 0.2rem;
              text-align: center;
              height: 0.4rem;
            }
            .icon-unfinished{
              background: #CCCCCC !important;
            }
            .icon-item{
              margin: 0.16rem 0;
              width: 0.24rem;
              height: 0.24rem;
              background: #3F7C53;
              border-radius: 0.12rem;
              position: relative;
              left: 50%;
              transform: translateX(-20%);
              &::before{
                width: 0.6rem;
                height: 0.02rem;
                left: -0.6rem;
                top: 0.1rem;
                background: #ECECEC;
                position: absolute;
                content: '';
              }
              &:after{
                width: 1.2rem;
                height: 0.02rem;
                right: -1.2rem;
                top: 0.1rem;
                background: #ECECEC;
                position: absolute;
                content: '';
              }
            }
          }
        }
        .timing-img{
          width: 1.5rem;
          position: absolute;
          bottom: -0.24rem;
          left: 0.48rem;
        }
        .update-time{
          font-size: 0.24rem;
          color: #9B9B9B;
          margin-left: 0.6rem;
        }
        .line-chart{
          background: #fff;
          width: 6.9rem;
          margin: 0 0.3rem 0.6rem;
          border-radius: 0.32rem;
          position: relative;
          .chart-title{
            justify-content: space-between;
            align-items: center;
            padding: 0.36rem 0.32rem;
            div{
              &:nth-child(1){
                color: #141A22;
                font-size: 0.36rem;
              }
              &:nth-child(2){
                color: #999;
                font-size: 0.26rem;
              }
            }
          }
        }
        .site-img{
          width: 2.4rem;
          height: 1.8rem;
          margin-right: 0.2rem;
          border-radius: 0.16rem;
        }
        .title{
          align-items: center;
          justify-content: space-between;
          padding: 0.2rem 0.3rem;
          border-top: 0.1rem solid #3F7C53;
          background: #fff;
          .logo-img{
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 0.3rem;
          }
          .title_left{
            align-items: center;
          }
          .title_mid{
            margin-left: 0.3rem;
          }
        }
        .content{
          margin-top: 0.3rem;
          .van-cell::after {
            border-bottom:0;
          }
        }
        .suggestion{
          padding: 0.6rem 1.5rem;
          color: #999;
          font-size: 0.26rem;
          span{
            color: #3F7C53;
          }
        }
        .deatils{
          background: #F7F8F9;
          padding:0 0 0.3rem 0;
          .deatils_title{
            height: 1rem;color: #3F7C53;line-height: 1rem;margin-left: 0.3rem
          }
          .deatils_cell{
            padding-bottom: 150px
          }
        }

      }
      </style>
      <style>
        .waitingSign .van-field__label{
          font-size:15px;
          font-family:PingFangSC-Regular,PingFang SC;
          font-weight:400;
          color: #333333;
        }
        .waitingSign .van-field__value .van-field__control{
          font-size:15px;
          font-family:PingFangSC-Regular,PingFang SC;
          font-weight:400;
          color: #999999;
        }
        .waitingSign .van-cell{
          border-bottom:1px solid #eeeeee;
        }
        .signed .van-cell{
          padding: 0.2rem 0.3rem;
          font-size: 0.3rem;
        }
      </style>

